<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_statements')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card>

            <b-card-header class="pb-50 mt-1 mb-2">
                <h5>{{$t('label_statements')}}</h5>
            </b-card-header>

            <b-card-body>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class=""
                >
                    <div v-show="itemData.document_uz">
                        <a :href="$base_url  +'documents/download_statement?file=' + itemData.document_uz"
                           class="btn btn-outline-primary" target="_blank">
                            <feather-icon icon="FileIcon"></feather-icon>
                        </a>
                        <feather-icon class="cursor-pointer ml-1" size="16" color="red" icon="XIcon"
                                      @click="deleteFile('document_uz')"></feather-icon>
                    </div>

                    <validation-provider
                            class="mt-2"
                            :name="$t('label_document_uz')"
                            slim
                            #default="validationProps"

                    >
                        <b-form-group
                                :label="$t('label_document_uz')"
                        >

                            <file-input
                                    @files-updated="uploadFile('document_uz')"
                                    v-model="document_uz"
                                    class="file--uploader size--big drop-block mb-3"
                                    :max-files-count='10'
                                    :required="true"
                                    :multiple="false"
                                    allowed-extensions="*"
                                    :max-file-size='100'
                                    :url="''"
                            >
                                <template #uploadBody>
                                    <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                    <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                    <!--{{$t('label_add_file')}}-->
                                </template>

                                <template #errorBug="fileErrors">

                                    <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </template>
                            </file-input>
                        </b-form-group>

                    </validation-provider>
                    <div v-if="itemData.document_ud">
                        <b-button variant="outline-primary" target="_blank"
                                  :href="$base_url  +'documents/download_statement?file=' + itemData.document_ud">
                            <feather-icon icon="FileIcon"></feather-icon>
                        </b-button>
                        <feather-icon class="cursor-pointer ml-1" size="16" color="red" icon="XIcon"
                                      @click="deleteFile('document_ud')"></feather-icon>
                    </div>
                    <validation-provider
                            class="mt-2"
                            :name="$t('label_document_ud')"
                            slim
                            #default="validationProps"

                    >
                        <b-form-group
                                :label="$t('label_document_ud')"
                        >
                            <file-input
                                    @files-updated="uploadFile('document_ud')"
                                    v-model="document_ud"
                                    class="file--uploader size--big drop-block mb-3"
                                    :max-files-count='10'
                                    :required="true"
                                    :multiple="false"
                                    allowed-extensions="*"
                                    :max-file-size='100'
                                    :url="''"
                            >
                                <template #uploadBody>
                                    <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                    <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                    <!--{{$t('label_add_file')}}-->
                                </template>

                                <template #errorBug="fileErrors">

                                    <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </template>
                            </file-input>
                        </b-form-group>

                    </validation-provider>


                </validation-observer>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
        BFormGroup,
        BFormInput, BFormInvalidFeedback, BFormTextarea

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import fileInput from '@/views/components/fileUploadInput'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver, BFormTextarea,
            fileInput
        },

        props: ['selectOption'],

        data() {
            return {
                PREFIX: 'statement',
                itemData: {},
                document_uz: [],
                document_ud: []
            }
        },

        created() {

            this.getPageData();
        },

        methods: {

            getPageData() {
                this.async('get', '/documents/statements', {params: {}}, function (res) {
                    this.itemData = res.data;
                });
            },

            uploadFile(file_type) {

                if (this[file_type].length > 0) {


                    let formData = new FormData();

                    formData.append('doc_type', file_type);

                    formData.append('document', this[file_type][0]);

                    this.async('post', '/documents/' + this.PREFIX + 's', formData, function (resp) {
                        this[file_type] = [];
                        this.getPageData();
                    }, false, {headers: {'Content-Type': 'multipart/form-data'}});
                }
            },

            deleteFile(file_type) {

                this.confirmDeleting((result) => {
                    this.async('delete', '/documents/' + this.PREFIX + 's/1', {params: {type:file_type}}, function (resp) {
                        this.getPageData();
                    });
                })
            },

        }


    }
</script>

